import React from "react"

import Layout from "../components/layout"
import SEO from "../components/SEO"

import ButtonLink from "../components/ButtonLink"
import BackgroundContainer from "../components/containers/BackgroundContainer"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <BackgroundContainer>
      <div className=" grid justify-center text-center mt-20">
        <h1>404: Page Not Found</h1>
        <p className=" mt-10">Oops! You just hit a route that doesn't exist.</p>
        <ButtonLink
          to="/"
          className="mt-10 w-auto justify-self-center uppercase tracking-wide"
        >
          Home Page
        </ButtonLink>
      </div>
    </BackgroundContainer>
  </Layout>
)

export default NotFoundPage
